import React, { useEffect, useState } from "react";
import axios from "../axios";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import AgentDetails from "./Modals/AgentDetails";
import { FiEye, FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";

import UpdateAgent from "./Modals/UpdateAgent";

const UserInfo = () => {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 9;
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showAgentModal, setShowAgentModal] = useState(false);
  const [updateAgentModal, setUpdateAgentModal] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const inputWidth = screenWidth > 425 ? "250px" : "1px";

  const fetchApiData = () => {
    axios.get("/user/getAgents").then((res) => {
      setData(res.data);
      //console.log(res.data);
    });
  };

  const deleteAgent = (id) => {
    axios
      .patch(`/user/deleteUser/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        fetchApiData();
      })
      .catch((err) => {
        toast.error(err.res.data.message);
      });
  };

  useEffect(() => {
    fetchApiData();
  }, [updateAgentModal]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="pcoded-main-container">
        {showAgentModal && (
          <AgentDetails
            show={showAgentModal}
            agent={data[selectedAgent]}
            onHide={() => setShowAgentModal(false)}
          />
        )}
        {updateAgentModal && (
          <UpdateAgent
            show={updateAgentModal}
            agent={data[selectedAgent]}
            onHide={() => setUpdateAgentModal(false)}
          />
        )}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between bg-light">
                        <h5 className="mt-2">Agents</h5>
                      </div>
                      <div className="card-block table-border-style">
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Contact</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.map((item, i) => (
                                <tr key={item?.id}>
                                  <th scope="row">{i + 1}</th>
                                  <td>{item?.username}</td>
                                  <td>{item?.email}</td>
                                  <td>{item?.phone}</td>
                                  <td>
                                    {item?.isVerified
                                      ? "Verified"
                                      : "Unverified"}
                                  </td>
                                  <td className="d-flex align-items-center">
                                    <FiEdit
                                      size={19}
                                      className="ml-1 c_pointer"
                                      onClick={() => {
                                        setSelectedAgent(itemOffset + i);
                                        setUpdateAgentModal(true);
                                      }}
                                    />
                                    <FiEye
                                      onClick={() => {
                                        setSelectedAgent(itemOffset + i);
                                        setShowAgentModal(true);
                                      }}
                                      size={20}
                                      className="ml-2 c_pointer"
                                    />
                                    <MdDeleteForever
                                      size={20}
                                      className="ml-2 c_pointer"
                                      onClick={() => deleteAgent(item.id)}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeClassName="active"
          />
        </div>
      </div>
    </>
  );
};

export default UserInfo;
