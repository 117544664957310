import React, { useEffect, useState } from "react";
import axios from "../axios";
import ReactPaginate from "react-paginate";
import AgentDetails from "./Modals/AgentDetails";

import { FiEye } from "react-icons/fi";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";

const OwnersInfo = () => {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  //const [showOwnerModal, setShowOwnerModal] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const inputWidth = screenWidth > 425 ? "250px" : "1px";

  const fetchApiData = () => {
    axios.get("/user/getOwners").then((res) => {
      setData(res.data);
      console.log(res.data);
    });
  };

  useEffect(() => {
    fetchApiData();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const deleteOwner = (id) => {
    console.log("call hua hai");
    axios
      .patch(`/user/deleteUser/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        fetchApiData();
      })
      .catch((err) => {
        toast.error(err.res.data.message);
      });
  };

  return (
    <>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between bg-light">
                        <h5 className="mt-2">Owners</h5>
                      </div>
                      <div className="card-block table-border-style">
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Contact</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.map((item, i) => (
                                <tr key={item?.id}>
                                  <th scope="row">{i + 1}</th>
                                  <td>{item?.username}</td>
                                  <td>{item?.email}</td>
                                  <td>{item?.phone}</td>
                                  <td>
                                    {item?.isVerified
                                      ? "Verified"
                                      : "Unverified"}
                                  </td>
                                  <td>
                                    <MdDeleteForever
                                      size={20}
                                      className="ml-2 c_pointer"
                                      onClick={() => deleteOwner(item.id)}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeClassName="active"
          />
        </div>
      </div>
    </>
  );
};

export default OwnersInfo;
