import React, { useEffect, useState } from "react";
import axios from "../axios";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";

const Categories = () => {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 9;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showCategory, setShowCategory] = useState(false);
  const [categoryTitle, setcategoryTitle] = useState("");
  const [categoryImage, setcategoryImage] = useState(null);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const inputWidth = screenWidth > 425 ? "250px" : "1px";

  //   const fetchApiData = () => {
  //     axios.get("/admin/getUsers").then((res) => {
  //       setData(res.data);
  //     });
  //   };
  const fetchCategory = () => {
    axios.get("/category").then((res) => {
      setData(res.data);
      //console.log(res.data);
    });
  };

  const addCategory = async () => {
    let fd = new FormData();
    fd.append("file", categoryImage);
    fd.append("title", categoryTitle);
    await axios
      .post("/category", fd)
      .then((res) => {
        toast.success(res.data.message);
        fetchCategory();
        setShowCategory(false);
        setcategoryTitle("");
        setcategoryImage(null);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  const deleteCategory = (id) => {
    axios
      .delete(`/category/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        fetchCategory();
      })
      .catch((err) => {
        toast.error(err.res.data.message);
      });
  };

  const handleFileInputChange = (event) => {
    const icon = event.target.files[0];
    setcategoryImage(icon);
  };

  useEffect(() => {
    // fetchApiData();
    fetchCategory();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data?.categories?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.categories?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data.categories]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.categories.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="col-lg-12">
                    <div className="card ">
                      <div className="card-header d-flex justify-content-between bg-light">
                        <div className="d-flex">
                          <h5 className="mt-2">Categories</h5>
                          <i onClick={() => setShowCategory(!showCategory)}>
                            <MdOutlineAddCircleOutline
                              size={25}
                              className="mt-1 ml-2 cursor-pointer"
                            />
                          </i>
                        </div>
                        {showCategory && (
                          <div className="align-items-center">
                            <input
                              type="file"
                              onChange={handleFileInputChange}
                            />
                            <input
                              type="text"
                              placeholder="Add Category"
                              className="add-category"
                              value={categoryTitle}
                              onChange={(e) => setcategoryTitle(e.target.value)}
                            />
                            <button
                              className="btn btn-primary my-0"
                              onClick={addCategory}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="card-block table-border-style">
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Icon</th>
                                <th>Name</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.map((item, i) => (
                                <tr key={item?.id}>
                                  <th scope="row">{i + 1}</th>
                                  <td>
                                    <img
                                      src={item?.icon}
                                      alt=""
                                     
                                      className="category_icon_img my-0"
                                    />
                                  </td>
                                  <td>{item?.title}</td>
                                  <td className="text-center">
                                    <AiOutlineDelete
                                      size={20}
                                      style={{ color: "red" }}
                                      className="cursor-pointer"
                                      onClick={() => deleteCategory(item.id)}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeClassName="active"
          />
        </div>
      </div>
    </>
  );
};

export default Categories;
