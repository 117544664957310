import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import axios from "../axios";
const ChartLine = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get("/order/getDashboardOrders").then((res) => {
      setData(res.data);
    });
  }, []);

  const chartData = {
    series: [data?.pendingOrders, data?.totalOrders],
    labels: ["Pending", "Completed"],
  };

  const chartOptions = {
    chart: {
      type: "pie",
      height: 400,
      width: 700,
    },
    labels: chartData.labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <div className="row">
      <div className="mixed-chart">
        <ReactApexChart
          options={chartOptions}
          series={chartData.series}
          type="pie"
          height={300}
          width={700}
        />
        {/* <Chart options={options} series={series} type="area" width="700px" /> */}
      </div>
    </div>
  );
};

export default ChartLine;
