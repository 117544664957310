import React, { useState } from "react";
import axios from "../axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const SignIn = () => {
  const cookies = new Cookies();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const setValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setLoginData({ ...loginData, [name]: value });
  };

  const login = (e) => {
    e.preventDefault();
    axios
      .post("/auth/login", {
        ...loginData,
        deviceToken: "admin",
        role: "admin",
      })
      .then((res) => {
        if (res.data.user.role === "admin") {
          cookies.set("user", res.data);
          window.location.href = "/";
        } else {
          toast.error("Invalid Credentials!");
        }
      })

      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <form className="auth-wrapper" onSubmit={login}>
      <div className="auth-content">
        <div className="auth-bg">
          <span className="r"></span>
          <span className="r s"></span>
          <span className="r s"></span>
          <span className="r"></span>
        </div>
        <div className="card _signIn">
          <div className="card-body text-center">
            <div className="mb-4">
              <i className="feather icon-unlock auth-icon"></i>
            </div>
            <h3 className="mb-4">Login</h3>
            <div className="input-group mb-3">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={loginData.email}
                onChange={setValue}
              />
            </div>
            <div className="input-group mb-4">
              <input
                type="password"
                name="password"
                className="form-control"
                onChange={setValue}
                placeholder="Password"
                value={loginData.password}
              />
            </div>
            <button className="btn btn-primary shadow-2 mb-4" type="submit">
              Login
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SignIn;
