import React from "react";
import Modal from "react-bootstrap/Modal";

const OrderDetails = ({ show, onHide, order }) => {
  //console.log(order);
  return (
    <Modal show={show} onHide={onHide} className="_modalWidth">
      <div className="main-body ">
        <div className="page-wrapper">
          <div className="row ">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>{order?.owner?.username}</h5>
                  <i
                    className="feather icon-x float-right c_pointer"
                    onClick={onHide}
                  ></i>
                </div>
                <div className="card-block table-border-style">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Area</th>
                          <th>Rooms</th>
                          <th>Bathrooms</th>
                          <th>Kitchens</th>
                          <th>Halls</th>
                          <th>Address</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{order?.title}</td>
                          <td>{order?.area}</td>
                          <td>{order?.rooms}</td>
                          <td>{order?.bathrooms}</td>
                          <td>{order?.kitchens}</td>
                          <td>{order?.halls}</td>
                          <td>{order?.address}</td>
                          <td>{order?.description}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>Images</h5>
                </div>
                <div className="row ml-1">
                  {order?.images?.map((image, i) => (
                    <div className="col-md-6" key={i}>
                      <div>
                        <img
                          src={image}
                          alt="Preview"
                       
                          className="_documents"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderDetails;
