import React, { useState, useEffect } from "react";
import axios from "../axios";
import Cookies from "universal-cookie";
import { FiEdit3 } from "react-icons/fi";
import { BsCurrencyDollar } from "react-icons/bs";
import { toast } from "react-toastify";

const SetCharges = ({ data }) => {
  const [show, setShow] = useState(false);

  const [value, setValue] = useState(data.value);

  const addPakage = () => {
    axios
      .post("/user/packages", { type: data.type, price: value })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };
  const cookie = new Cookies();
  const user = cookie.get("user");
  return (
    <div className="col-md-6 col-xl-4">
      <div className="card daily-sales">
        <div className="card-block">
          <h5 className="mb-4">{data.title}</h5>
          <div className="row d-flex align-items-center">
            <div className="col-9 d-flex align-items-center">
              <BsCurrencyDollar
                size={30}
                className="text-gray align-items-center"
              />
              <h3 className="f-w-300 align-items-center mt-2">
                <b> {data.value} </b>
              </h3>
            </div>
            <i onClick={() => setShow(!show)} className="cursor-pointer">
              <FiEdit3 size={25} />
            </i>
          </div>
          {show && (
            <div className="d-flex mt-4 ">
              <input
                className="percentage-input"
                type="number"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <button className="btn btn-primary ml-3" onClick={addPakage}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetCharges;
