import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import axios from "../../axios";

const UpdateAgent = ({ show, onHide, agent }) => {
  console.log(agent);
  const [status, setStatus] = useState(agent?.isVerified);
  const updateStatus = (e) => {
    e.preventDefault();
    axios
      .patch(`/agent/${agent.id}`, { isVerified: status })
      .then((res) => {
        toast.success(res.data.message);
        onHide();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <Modal show={show} onHide={onHide}>
      <div className="main-body ">
        <div className="page-wrapper">
          <div className="row ">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header _modalStyles bg-light">
                  <h5>Update Status</h5>
                  <i
                    className="feather icon-x float-right c_pointer"
                    onClick={onHide}
                  ></i>
                </div>
                <div className="card-body">
                  <h5>{agent?.username}</h5>

                  <div className="row">
                    <div className="col-md-12">
                      <form onSubmit={updateStatus}>
                        <div className="form-group">
                          <label for="name">Account Status</label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            onChange={(e) => setStatus(e.target.value)}
                            defaultValue={status}
                          >
                            <option value={true}>Approve</option>
                            <option value={false}>Reject</option>
                          </select>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary mt-5 float-right"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateAgent;
