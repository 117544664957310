import React, { useState, useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import axios from "../axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import SetCharges from "./SetCharges";

const AdminProfile = () => {
  const [data, setData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [changePassword, setChangePassword] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [termsConditions, setTermsConditions] = useState("");
  const [trialLimit, setTrialLimit] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [phonenum, setPhoneNum] = useState("");
  const [tags, setTags] = useState([]);
  const [chargesData, setChargesData] = useState([]);
  const [show, setShow] = useState(false);
  const [policy, setPolicy] = useState([]);
  const [showpolicy, setShowPolicy] = useState(false);
  const [terms, setTerms] = useState([]);
  const [showTerms, setShowTerms] = useState(false);
  const [UpdateNumber, setUpdateNumber] = useState(false);

  const getMargin = () => {
    axios.get("/user/packages").then((res) => {
      setData(res.data?.data);
      setTrialLimit(res.data?.data?.freeDays);
    });
  };

  useEffect(() => {
    getMargin();
    getPolicy();
    getTerms();
    ContactUs();
  }, []);

  const ContactUs = () => {
    axios.get("/user/adminPhone").then((res) => {
      setPhone(res.data);
      console.log(res.data);
    });
  };
  const addContact = () => {
    axios
      .post("/user/adminPhone", { phone: phonenum })
      .then((res) => {
        toast.success(res.data.message);
        setPhoneNum("");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  const addPakage = () => {
    axios
      .post("/user/packages", { type: "freeDays", price: trialLimit })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  const updatePassword = (e) => {
    e.preventDefault();
    axios
      .post("/user/changePassword", { oldPassword, password: newPassword })
      .then((res) => {
        toast.success(res.data.message);
        setNewPassword("");
        setOldPassword("");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  const cookie = new Cookies();
  const user = cookie.get("user");
  // console.log(user);

  const addPolicy = () => {
    axios
      .post("/privacy", { description: privacyPolicy, name: "privacy" })
      .then((res) => {
        toast.success(res.data.message);
        setPrivacyPolicy("");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };
  const getPolicy = () => {
    axios.get("/privacy").then((res) => {
      setPolicy(res.data);
      // console.log(res.data);
    });
  };

  const addTerms = () => {
    axios
      .post("/privacy", { description: termsConditions, name: "terms" })
      .then((res) => {
        toast.success(res.data.message);
        setTermsConditions("");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };
  const getTerms = () => {
    axios.get("/privacy/terms").then((res) => {
      setTerms(res.data);
      console.log(res.data);
    });
  };

  useEffect(() => {
    setChargesData([
      {
        title: "Monthly Charges",
        type: "monthlyCharges",
        value: data?.monthlyCharges || 0,
      },
      {
        title: "Yearly Charges",
        type: "yearlyCharges",
        value: data?.yearlyCharges || 0,
      },
      {
        title: "Lifetime Charges",
        type: "lifetimeCharges",
        value: data?.lifetimeCharges || 0,
      },
    ]);
  }, [data]);

  return (
    <div className="pcoded-main-container">
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card daily-sales"
                      style={{ backgroundColor: "#EFF2F5" }}
                    >
                      <div className="card-block d-lg-flex">
                        <div className="col-lg-6">
                          <h3 className="mb-4 ml-4">Account Details</h3>
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-8 ml-5 mt-2">
                              <h5>
                                <b>Name:</b> {user?.user?.username}{" "}
                              </h5>

                              <h5 className="mt-3">
                                <b> Email: </b> {user?.user?.email}
                              </h5>
                              <h5 className="mt-3 d-flex">
                                <b> Contact : </b> {phone?.phone}
                                <span className="mx-2 mt-1">
                                  {" "}
                                  <p className="text-primary cursor-pointer">
                                    <i
                                      onClick={() =>
                                        setUpdateNumber(!UpdateNumber)
                                      }
                                    >
                                      Change
                                    </i>
                                  </p>
                                </span>
                              </h5>

                              <div>
                                <p
                                  className="mt-3 text-primary cursor-pointer"
                                  onClick={() =>
                                    setChangePassword(!changePassword)
                                  }
                                >
                                  Change Password
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {UpdateNumber && (
                            <div>
                              <h5>Enter</h5>
                              <input
                                className="form-control mt-2 "
                                value={phonenum}
                                placeholder="New Phone Number"
                                onChange={(e) => setPhoneNum(e.target.value)}
                              />
                              <button
                                className="btn btn-primary mt-2 "
                                onClick={addContact}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>

                        {changePassword && (
                          <div className="col-lg-6">
                            <h4>Change Password</h4>
                            <form
                              onSubmit={updatePassword}
                              className="form-group mt-1"
                            >
                              <input
                                className="form-control mt-2 w-75 position-relative"
                                type={show ? "text" : "password"}
                                value={oldPassword}
                                placeholder="Old Password"
                                onChange={(e) => setOldPassword(e.target.value)}
                              />{" "}
                              <AiOutlineEye
                                size={25}
                                className="pass-icon"
                                onClick={() => setShow(!show)}
                              />
                              <input
                                className="form-control mt-2 w-75 position-relative"
                                type={show ? "text" : "password"}
                                value={newPassword}
                                placeholder="New Password"
                                onChange={(e) => setNewPassword(e.target.value)}
                              />{" "}
                              <AiOutlineEye
                                size={25}
                                className="pass-icon2"
                                onClick={() => setShow(!show)}
                              />
                              <button className="btn btn-primary mt-2 ">
                                Save
                              </button>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {chargesData?.map((data) => (
                    <SetCharges data={data} />
                  ))}

                  <div className="col-lg-6">
                    <div
                      className="card daily-sales"
                      style={{ backgroundColor: "#EFF2F5" }}
                    >
                      <div className="card-block d-lg-flex">
                        <div className="col-lg-12">
                          <h3 className="mb-4">Privacy Policy</h3>
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-12 mt-2">
                              <textarea
                                className="form-control mt-2 position-relative"
                                type="text"
                                value={privacyPolicy}
                                placeholder="Add Description"
                                onChange={(e) =>
                                  setPrivacyPolicy(e.target.value)
                                }
                              />{" "}
                              <button
                                className="btn btn-primary mt-2 float-right"
                                onClick={() => addPolicy()}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          {showpolicy && (
                            <div className="mt-5">
                              <p> {policy?.privacy?.description}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <p>
                          {" "}
                          <i
                            onClick={() => setShowPolicy(!showpolicy)}
                            className="cursor-pointer ml-5"
                          >
                            Show Added
                          </i>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="card daily-sales"
                      style={{ backgroundColor: "#EFF2F5" }}
                    >
                      <div className="card-block d-lg-flex">
                        <div className="col-lg-12">
                          <h3 className="mb-4">Terms & Conditions</h3>
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-12 mt-2">
                              <textarea
                                className="form-control mt-2 position-relative"
                                type="text"
                                value={termsConditions}
                                placeholder="Add Description"
                                onChange={(e) =>
                                  setTermsConditions(e.target.value)
                                }
                              />{" "}
                              <button
                                className="btn btn-primary mt-2 float-right"
                                onClick={() => addTerms()}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          {showTerms && (
                            <div className="mt-5">
                              <p> {terms?.privacy?.description}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <p>
                          {" "}
                          <i
                            onClick={() => setShowTerms(!showTerms)}
                            className="cursor-pointer ml-5"
                          >
                            Show Added
                          </i>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div
                      className="card daily-sales"
                      style={{ backgroundColor: "#EFF2F5" }}
                    >
                      <div className="card-block d-lg-flex">
                        <div className="col-lg-12">
                          <h3 className="mb-4 ml-0">Set Free Trial Limit</h3>
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-12 mt-2">
                              <input
                                className="form-control mt-2 position-relative"
                                type="number"
                                value={trialLimit}
                                placeholder="Add"
                                onChange={(e) => setTrialLimit(e.target.value)}
                              />{" "}
                              <button
                                className="btn btn-primary float-right my-3"
                                onClick={() => addPakage()}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h4 className="mb-3">
                          {" "}
                          <i className="cursor-pointer ml-5">
                            Current: {data?.freeDays}{" "}
                          </i>{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
