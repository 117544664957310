import Axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const user = cookies.get("user");
const axios = Axios.create({
  //baseURL: "http://192.168.18.12:5000/v1",
  baseURL: "http://localhost:5000/v1/",
  headers: { Authorization: `Bearer ${user?.tokens?.access?.token}` },
});

export default axios;
