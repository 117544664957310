import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import moment from "moment/moment";

const AgentDetails = ({ show, onHide, agent }) => {
  console.log(agent);
  return (
    <Modal show={show} onHide={onHide} className="_modalWidth">
      <div className="main-body ">
        <div className="page-wrapper">
          <div className="row ">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>User Details</h5>
                  <i
                    className="feather icon-x float-right c_pointer"
                    onClick={onHide}
                  ></i>
                </div>
                <div className="card-block table-border-style">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Contact</th>
                          <th>Email</th>
                          <th>Account Created</th>
                          <th>Account Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{agent.username}</td>

                          <td>{agent.phone}</td>
                          <td>{agent.email}</td>
                          <td>
                            {moment(agent.createdAt).format("MMMM Do YYYY")}
                          </td>
                          <td>
                            {agent.isAccountVerified
                              ? "Verified"
                              : "Unverified"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>Selfie</h5>
                </div>
                <div className="row ml-1">
                  <div>
                    <img
                      src={agent.Selfie}
                      alt="Preview"
                      className="_documents"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>Profile</h5>
                </div>
                <div className="row ml-1">
                  <div>
                    <img
                      src={agent.profile}
                      alt="Preview"
                     
                      className="_documents"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>NID</h5>
                </div>
                <div className="row ml-1">
                  <div>
                    <img
                      src={agent.NID}
                      alt="Preview"
                 
                      className="_documents"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>Car Picture</h5>
                </div>
                <div className="row ml-1">
                  <div>
                    <img
                      src={agent.carPicture}
                      alt="Preview"
                       
                      className="_documents"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>Driving License Front</h5>
                </div>
                <div className="row ml-1">
                  <div>
                    <img
                      src={agent.drivingLicense.front}
                      alt="Preview"

                      className="_documents"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>Driving License Back</h5>
                </div>
                <div className="row ml-1">
                  <div>
                    <img
                      src={agent.drivingLicense.back}
                      alt="Preview"
                       
                      className="_documents"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AgentDetails;
