import React, { useState, useRef, useEffect } from "react";
import { CiMoneyBill } from "react-icons/ci";
import { FiUsers } from "react-icons/fi";
import { SlUser } from "react-icons/sl";
import { CgProfile } from "react-icons/cg";
import { MdSpaceDashboard, MdOutlineCategory } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "../axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import applogo from "../assets/images/applogo.png";
import avatar from "../assets/images/user/avatar-2.jpg";

const SideBar = ({ searchValue, setSearchValue }) => {
  const [isActive, setIsActive] = useState("dashboard");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mbMenu, setMbMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const reference = useRef(null);
  useEffect(() => {
    document.addEventListener("click", HandleClick, true);

    const windowLocation = window.location.href.split("/").at(-1);
    if (windowLocation === "driver_info") {
      setIsActive("drivers");
    } else if (windowLocation === "user_info") {
      setIsActive("users");
    } else if (windowLocation === "admin_profile") {
      setIsActive("profile");
    }
  }, []);

  const HandleClick = (e) => {
    if (!reference.current.contains(e.target)) {
      setMbMenu(false);
    }
  };

  useEffect(() => {
    setMbMenu(false);
  }, [isActive]);

  const cookie = new Cookies();
  const user = cookie.get("user");
  //console.log(user);

  const signOut = () => {
    axios
      .post("/auth/logout", { refreshToken: user.tokens.refresh.token })
      .then((resp) => {
        cookie.remove("user");
        window.location.href = "/login";
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      <nav
        // className="pcoded-navbar"
        ref={reference}
        className={`pcoded-navbar ${mobileMenu && "navbar-collapsed"} ${
          mbMenu && "mob-open"
        }`}
      >
        <div className="navbar-wrapper">
          <div className="navbar-brand header-logo">
            <div className="b-brand">
              <div>
                <img src={applogo} className="_logoimg" alt="User-Profile" />
              </div>
              <span className="b-title">Clean Now</span>
            </div>
            <a
              className={`mobile-menu ${mobileMenu && "on"}`}
              onClick={() => setMobileMenu(!mobileMenu)}
              id="mobile-collapse"
              href="#"
            >
              <span></span>
            </a>
          </div>

          <div className="navbar-content scroll-div">
            <ul className="nav pcoded-inner-navbar mt-3">
              <li
                data-username="dashboard Default Ecommerce CRM Analytics Crypto Project"
                className={`nav-item ${isActive === "dashboard" && "active"}`}
                onClick={() => setIsActive("dashboard")}
              >
                <Link to="/">
                  <span className="pcoded-micon">
                    <MdSpaceDashboard />
                  </span>
                  <span className="pcoded-mtext"> Dashboard</span>
                </Link>
              </li>
              <li
                data-username="form elements advance componant validation masking wizard picker select"
                className={`nav-item ${isActive === "users" && "active"}`}
                onClick={() => setIsActive("users")}
              >
                <Link to="/user_info">
                  <span className="pcoded-micon">
                    <FiUsers />
                  </span>
                  <span className="pcoded-mtext">Agents</span>
                </Link>
              </li>
              <li
                data-username="Table bootstrap datatable footable"
                className={`nav-item ${isActive === "owners" && "active"}`}
                onClick={() => setIsActive("owners")}
              >
                <Link to="/owners">
                  <span className="pcoded-micon">
                    <SlUser />
                  </span>
                  <span className="pcoded-mtext">Owners</span>
                </Link>
              </li>
              <li
                data-username="Table bootstrap datatable footable"
                className={`nav-item ${isActive === "orders" && "active"}`}
                onClick={() => setIsActive("orders")}
              >
                <Link to="/orders">
                  <span className="pcoded-micon">
                    <CiMoneyBill />
                  </span>
                  <span className="pcoded-mtext">Orders</span>
                </Link>
              </li>
              <li
                data-username="form elements advance componant validation masking wizard picker select"
                className={`nav-item ${isActive === "categories" && "active"}`}
                onClick={() => setIsActive("categories")}
              >
                <Link to="/categories">
                  <span className="pcoded-micon">
                    <MdOutlineCategory />
                  </span>
                  <span className="pcoded-mtext">Categories</span>
                </Link>
              </li>
              <li
                data-username="Table bootstrap datatable footable"
                className={`nav-item ${isActive === "profile" && "active"}`}
                onClick={() => setIsActive("profile")}
              >
                <Link to="/admin_profile">
                  <span className="pcoded-micon">
                    <CgProfile />
                  </span>
                  <span className="pcoded-mtext">Profile</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <header className="navbar pcoded-header navbar-expand-lg navbar-light">
        <div className="m-header">
          <a
            className="mobile-menu"
            onClick={() => setMbMenu(!mbMenu)}
            id="mobile-collapse1"
            href="#"
          >
            <span></span>
          </a>
          <a href="#" className="b-brand">
            <div className="b-bg">
              <img src={applogo} className="_logoimg" alt="User-Profile" />
            </div>
            <span className="b-title">Clean Now</span>
          </a>
        </div>
        <a className="mobile-menu" id="mobile-header" href="#">
          <i className="feather icon-more-horizontal"></i>
        </a>
        <div className="pro-head d-flex">
          <div className="justify-content-center position-relative">
            <img src={avatar} className="img-radius" alt="User-Profile" />
            <span style={{ color: "black" }}>{user?.user?.username}</span>
          </div>
          <div className="dud-logout color text-dark">
            {showDropdown && (
              <div className="collapse navbar-collapse d-flex" ref={wrapperRef}>
                <div className="dropdown drp-user">
                  <div className="dropdown-menu dropdown-menu-right profile-notification show">
                    <div className="pro-head pro-head-drop">
                      <span>{user?.user?.username} </span>
                    </div>
                    <ul className="pro-body">
                      <li>
                        <Link
                          to="/admin_profile"
                          className="dropdown-item"
                          onClick={() => setShowDropdown(!showDropdown)}
                        >
                          <i className="feather icon-user"></i> Profile
                        </Link>
                      </li>
                      <li onClick={signOut}>
                        <a href="#" className="dropdown-item">
                          <i className="feather icon-log-out"></i> Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            <i
              className="feather icon-settings mr-5"
              onClick={() => setShowDropdown(!showDropdown)}
              style={{ fontSize: "16px" }}
            ></i>
          </div>
        </div>
      </header>
    </>
  );
};

export default SideBar;
