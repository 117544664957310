import React, { useState, useEffect, useRef } from "react";
import { GrCompliance } from "react-icons/gr";
import axios from "../axios";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { MdPendingActions } from "react-icons/md";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import OrderDetails from "./Modals/OrderDetails";
import UpdateStatus from "./Modals/UpdateStatus";
const UserInfo = () => {
  const [data, setData] = useState([]);

  const [pendingOrders, setPendingOrders] = useState(true);
  const [completedOrders, setCompletedOrders] = useState(false);
  const [selectTable, setSelectedTable] = useState("pending");
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [updateStatusModal, setUpdateStatusModal] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 9;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const inputWidth = screenWidth > 425 ? "250px" : "1px";

  const fetchApiData = () => {
    axios.get("ad/getAds").then((res) => {
      setData(res.data);
      console.log(res.data);
    });
  };
  useEffect(() => {
    fetchApiData();
  }, [updateStatusModal]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data?.ads?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.ads?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data?.ads?.length;
    setItemOffset(newOffset);
  };

  const deleteOrder = (id) => {
    axios
      .delete(`/user/getOwners/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        fetchApiData();
      })
      .catch((err) => {
        toast.error(err.res.data.message);
      });
  };

  const inputRef = useRef(null);
  return (
    <div className="pcoded-main-container">
      {showOrderModal && (
        <OrderDetails
          show={showOrderModal}
          order={data.ads[selectedOrder]}
          onHide={() => setShowOrderModal(false)}
        />
      )}
      {updateStatusModal && (
        <UpdateStatus
          show={updateStatusModal}
          order={data.ads[selectedOrder]}
          onHide={() => setUpdateStatusModal(false)}
        />
      )}

      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-center bg-light">
                      <div className="">
                        <div className="d-md-flex">
                          <p className="cursor-pointer table__text">Orders</p>
                        </div>
                      </div>
                    </div>
                    <div className="card-block table-border-style">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Ad Title</th>
                              <th>Price</th>
                              <th>Requested On</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems?.map((item, i) => (
                              <tr key={item.id}>
                                <tr>{i + 1}</tr>
                                <td>{item?.title}</td>
                                <td>{item?.cost}</td>
                                <td>
                                  {moment(item?.createdAt).format(
                                    "MMMM Do YYYY"
                                  )}
                                </td>
                                <td>
                                  {item?.isConfirmed ? "Approved" : "Pending"}
                                </td>
                                <td>
                                  <i
                                    className="cursor-pointer mx-2"
                                    onClick={() => {
                                      setSelectedOrder(itemOffset + i);
                                      setShowOrderModal(true);
                                    }}
                                  >
                                    <AiOutlineEye size={24} />
                                  </i>
                                  {"  "}
                                  <i className="cursor-pointer">
                                    <MdDeleteForever
                                      size={23}
                                      className="c_pointer"
                                      onClick={() => deleteOrder(item.id)}
                                    />{" "}
                                  </i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeClassName="active"
        />
      </div>
    </div>
  );
};

export default UserInfo;
